<template>
  <el-dialog title="编辑任务编号" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 250px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '150px' }"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: [],
      form: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.btnloadSubmit = false
      this.form = {}
    },
    async open(id) {
      this.clear()
      if (id) {
        this.show = true
        this.getDetail(id)
        this.$nextTick(() => {
          this.$refs.vForm.fillData({})
        })
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getTaskThirdNo() {
      const res = await this.$api.GetSystemDataDicList({ DataCategory: 'TaskThirdNo' })
      const fromLabels = res.list.map((item) => {
        return {
          cmp: 'input',
          label: item.dataName,
          prop: item.dataValue
        }
      })
      this.fromLabels = fromLabels
    },
    // 获取详情
    async getDetail(id) {
      await this.getTaskThirdNo()
      const res = await this.$api.GetTaskManageInfo({ id })
      const form = { id }
      this.fromLabels.map((item) => {
        form[item.prop] = res[item.prop]
      })
      this.form = form
      this.$refs.vForm.fillData(form)
    },
    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        let res = await this.$api.EditTaskManage(Object.assign({}, this.form)).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('任务编号编辑成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>
