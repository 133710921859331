<template>
  <div>
    <el-dialog
      :title="(id ? '编辑' : '添加') + title"
      :visible.sync="show"
      :append-to-body="true"
      :close-on-click-modal="false"
      top="4vh"
      width="1200px"
    >
      <!-- 内容区域 -->
      <div class="dialog-wrap" style="height: 450px">
        <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }" @onInputChange="onInputChange"></FormView>
      </div>
      <span slot="footer">
        <el-button @click="show = false">取消</el-button>
        <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { regular } from '@/configs/validate'
const formCreatMerchant = [
  {
    cmp: 'input',
    label: '社会统一信用代码',
    prop: 'creditCode',
    col: 11,
    disabled: `form.isEdit`,
    itemConfig: {
      rules: [{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '商户号',
    col: 11,
    disabled: `form.isEdit`,
    config: { disabled: true, placeholder: '系统生成' },
    show: `!!form.creditCode`
  },
  {
    cmp: 'input',
    label: '登录账号',
    prop: 'loginName',
    col: 11,
    disabled: `form.isEdit`,
    itemConfig: {
      rules: [{ required: true, message: '请输入登录账号', trigger: 'blur' }]
    },
    show: `!!form.creditCode`
  },
  {
    cmp: 'input',
    label: '商户名称',
    prop: 'merchantName',
    col: 11,
    disabled: `form.isEdit`,
    itemConfig: {
      rules: [{ required: true, message: '请输入商户名称', trigger: 'blur' }]
    },
    show: `!!form.creditCode`
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 },
    show: `!!form.creditCode`
  },
  // 2层显示
  // 需要修改字段
  {
    cmp: 'select',
    label: '来源',
    prop: 'source',
    col: 11,
    options: {},
    itemConfig: {
      rules: [{ required: true, message: '请选择来源', trigger: 'change' }]
    },
    show: `!!form.creditCode`
  },
  {
    cmp: 'select',
    label: '渠道归属',
    prop: 'channelId',
    col: 11,
    show: `!!form.creditCode`,
    options: [],
    config: {
      style: { width: '100%' }
    },
    itemConfig: {
      rules: [{ required: true, message: '请选择商务归属', trigger: 'change' }]
    }
  },
  {
    cmp: 'select',
    label: '商务归属',
    prop: 'commerceList',
    col: 11,
    show: `!!form.creditCode`,
    options: [],
    config: {
      multiple: true,
      style: { width: '100%' }
    },
    itemConfig: {
      rules: [{ required: true, message: '请选择商务归属', trigger: 'change' }]
    }
  },
  {
    cmp: 'input',
    label: '联系人',
    prop: 'contract',
    col: 11,
    show: `!!form.creditCode`,
    itemConfig: {
      rules: [
        {
          min: 0,
          max: 50,
          message: '联系人不能超过50个字符',
          trigger: 'blur'
        }
      ]
    }
  },
  {
    cmp: 'input',
    label: '联系方式',
    prop: 'contractPhone',
    itemConfig: {
      rules: [
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    col: 11,
    show: `!!form.creditCode`
  },
  {
    cmp: 'input',
    label: '企业名称',
    prop: 'mainName',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入企业名称', trigger: 'blur' }]
    },
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`
  },

  {
    cmp: 'upload',
    label: '营业执照照片',
    prop: 'businessLicense',
    col: 11,
    show: `!!form.creditCode`,
    disabled: `form.isEdit||form.isAuthentication==1`
  },

  // other
  // {
  //   cmp: 'select',
  //   label: '业务类型',
  //   prop: 'businessType',
  //   col: 11,
  //   options: [{ key: 1, label: '自然人业务' }],
  //   show: `!!form.creditCode`,
  //   disabled: `true`
  // },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    col: 22,
    show: `!!form.creditCode`,
    config: { type: 'textarea', maxlength: 200 }
  },
  {
    sessionTitle: '增值功能',
    cmp: 'radio',
    label: '派单功能',
    prop: 'productTaskAssignment',
    options: [
      { key: 0, label: '关闭' },
      { key: 1, label: '开通' }
    ],
    col: 22,
    itemConfig: {
      rules: [{ required: true, message: '请选择是否开通派单功能', trigger: 'change' }]
    }
  }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      title: '商户',
      fromLabels: formCreatMerchant,
      employeeOptions: [],
      show: false,
      id: null
    }
  },
  methods: {
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      this.getEmployee()
      this.getChannel()
      this.getSource()
      if (obj && obj.merchantId) {
        // 编辑
        this.id = obj.merchantId
        this.getDetail()
      } else {
        // 添加
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ businessType: 1, commerceList: [], productTaskAssignment: 0 })
        })
      }
    },
    async getChannel() {
      const res = await this.$api.GetChannelInfoList()
      const options = (res.list || []).map((it) => {
        return {
          key: it.id,
          label: it.channelName
        }
      })
      this.fromLabels.find((item) => item.prop == 'channelId').options = options
    },
    async getEmployee() {
      const res = await this.$api.GetEmployeeInfoList()
      this.employeeOptions = (res.list || []).map((it) => {
        return {
          key: it.id,
          label: it.employeeName
        }
      })
      this.fromLabels.find((item) => item.prop == 'commerceList').options = []
    },
    async getSource() {
      const res = await this.$api.GetSystemDataDicList({ DataCategory: 'MerchantSource' })
      const options = (res.list || []).map((it) => {
        return {
          key: it.dataValue,
          label: it.dataName
        }
      })
      this.fromLabels.find((item) => item.prop == 'source').options = options
    },
    async getDetail() {
      const res = await this.$api.GetMerchantInfoDetail({ merchantId: this.id })
      this.changeChannelId(res.channelId, 'channelId')
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({
          ...res,
          commerceList: (res.commerceList || []).map((it) => it.commerceId),
          loginName: res.loginUser,
          isEdit: true
        })
      })
    },
    async submit() {
      // step1Form更新
      const form = await this.$refs.vForm.getForm()
      if (this.id) {
        // 编辑
        this.btnloadSubmit = true
        const { merchantId, mobile, source, channelId, commerceList, contract, contractPhone, remark, productTaskAssignment } = form
        await this.$api
          .EditMerchantInfo({ merchantId, mobile, source, channelId, commerceList, contract, contractPhone, remark, productTaskAssignment })
          .finally(() => {
            this.btnloadSubmit = false
          })
        this.$message.success('编辑成功')
        this.$emit('submit')
        this.show = false
      } else {
        // 添加
        let res = await this.$api.CheckMerchantInfo({
          mobile: form.mobile,
          loginName: form.loginName,
          contract: form.contract,
          commerceList: form.commerceList
        })
        if (res) {
          this.btnloadSubmit = true
          await this.$api.AddMerchantInfo(form).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        } else {
          this.$message.error('商户信息校验未通过')
        }
      }
    },
    onInputChange(val, key) {
      if (!this.id && key == 'creditCode') {
        this.creditCodeChange(val)
      }
      if (key == 'channelId') {
        this.$refs.vForm.upData({
          commerceList: []
        })
        this.changeChannelId(val)
      }
    },
    changeChannelId(val) {
      if (!val) {
        this.fromLabels.find((item) => item.prop == 'commerceList').options = []
      } else if (val == '00000000000000000000000000000000') {
        this.fromLabels.find((item) => item.prop == 'commerceList').options = this.employeeOptions || []
      } else {
        this.$api
          .GetChannelInfo({ Id: val })
          .then((res) => {
            const list = (res.channelEmployeeList || []).map((it) => {
              return {
                key: it.employeeId,
                label: it.employeeName
              }
            })
            this.fromLabels.find((item) => item.prop == 'commerceList').options = list || []
          })
          .catch((err) => {
            this.fromLabels.find((item) => item.prop == 'commerceList').options = []
          })
      }
    },
    async creditCodeChange(val) {
      const res = await this.$api.GetBusinessMain({ creditCode: val })
      const initObj = {
        isAuthentication: 0,
        mainName: null,
        businessLicense: null,
        remark: null
      }
      // 空处理
      if (!res.id) return this.$refs.vForm.upData(initObj)
      const { isAuthentication, mainName, businessLicense, remark } = res
      const mainId = res.id
      const organizationType = parseInt(res.organizationType)
      const isEntrust = parseInt(res.isEntrust)
      const baseObj = {
        mainId,
        isAuthentication,
        organizationType,
        mainName,
        businessLicense,
        isEntrust,
        remark
      }
      this.$refs.vForm.upData(Object.assign(initObj, baseObj))
    }
  }
}
</script>
<style lang="scss" scoped>
.fillTemplate {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 3fr;
  margin-bottom: 10px;
}
</style>
