<template>
  <div>
    <el-dialog :visible.sync="show" title="任务审核" width="40%">
      <div>
        <el-descriptions
          border
          title="基本信息"
          :column="2"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
        >
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="创建时间">{{
            detail.taskCreateTime || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="创建人">{{
            detail.creator || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="商户名称">{{
            detail.merchantName || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务类型">{{
            detail.taskTypeText || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="需求人数">{{
            detail.limitCount || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="工作时间"
            >{{ detail.limitTimeStart }}-{{ detail.limitTimeEnd }}</el-descriptions-item
          >
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="佣金范围">
            <span v-if="detail.commissionLimit != 0">
              <span v-format="'#,##0.00'">{{ detail.taskMinAmount }}</span> - <span v-format="'#,##0.00'">{{ detail.taskMaxAmount }}</span></span
            >
          </el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="商户编号">{{
            detail.memberCode || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务编号">{{
            detail.taskNumber || '无'
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务状态">{{
            detail.taskState || '无'
          }}</el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务名称">{{
            detail.taskName || '无'
          }}</el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="工作地点">{{
            detail.limitAddress || '无'
          }}</el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务内容">
            {{ detail.taskDescribe || '无' }}
            <!-- <span :title="detail.taskDescribe">
              {{ detail.taskDescribe && detail.taskDescribe.length >= 40 ? detail.taskDescribe.substr(0, 40) + '……' : detail.taskDescribe }}
            </span> -->
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="验收标准">
            {{ detail.acceptCriteria || '无' }}
            <!-- <span :title="detail.acceptCriteria">
              {{ detail.acceptCriteria && detail.acceptCriteria.length >= 40 ? detail.acceptCriteria.substr(0, 40) + '……' : detail.acceptCriteria }}
            </span> -->
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="备注">
            {{ detail.remark || '无' }}
            <!-- <span :title="detail.remark">
              {{ detail.remark && detail.remark.length >= 40 ? detail.remark.substr(0, 40) + '……' : detail.remark }}
            </span> -->
          </el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-form :model="form" :rules="rules" ref="vForm" label-width="120px">
          <el-form-item label="绑定税目" prop="merchantTaxId">
            <el-cascader
              v-model="form.merchantTaxId"
              :options="itemOptions"
              :props="{ value: 'merchantTaxId', label: 'merchantTaxName', emitPath: false }"
              :show-all-levels="false"
              placeholder="请选择"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.merchantTaxName }}</span>
                <span> {{ data.taxCode }} </span>
              </template>
            </el-cascader>
          </el-form-item>
          <!-- <el-form-item v-if="detail.supplierIsSync" label="任务场景" prop="scenesType">
            <el-select v-model="form.scenesType" placeholder="请选择">
              <el-option v-for="item in scenesOptions" :key="item.value" :label="item.label" :value="parseInt(item.value)"> </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="showReasonDialog">审核不通过</el-button>
        <el-button type="primary" @click="submit(1)" :loading="btnloadSubmit">审核通过</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button @click="showReason = !showReason">取消</el-button>
        <el-button @click="submit(2)" type="primary" :loading="btnloadSubmit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      detail: {},
      itemOptions: [],
      form: {},
      rules: {
        merchantTaxId: [{ required: true, message: '请选择绑定税目', trigger: 'change' }]
        // scenesType: [{ required: true, message: '请选择任务场景', trigger: 'change' }]
      },
      scenesOptions: [],
      show: false,
      showReason: false,
      reason: ''
    }
  },
  methods: {
    clear() {
      this.id = null
      this.detail = {}
      this.form = {}
      this.$nextTick(() => {
        if (this.$refs.vForm) this.$refs.vForm.clearValidate()
      })
    },
    open(row) {
      this.clear()
      this.id = row.id
      this.getDetail()
      this.GetSelectMerchantOptions(row.id)
      this.show = true
    },
    showReasonDialog() {
      this.showReason = true
    },
    async getDetail() {
      const res = await this.$api.GetTaskManageInfo({ Id: this.id })
      this.detail = res
      if (res.supplierIsSync) {
        this.getScenes()
        // this.form = { scenesType: res.scenesType }
      }
    },
    async getScenes() {
      const res = await this.$api.GetTaskScenestype()
      this.scenesOptions = res.list
    },
    async submit(e) {
      //e 1审核通过，0审核不通过
      if (e == 2 && !this.reason) return this.$message.error('请输入理由')
      this.btnloadSubmit = true
      if (e == 2) {
        // 审核不通过
        const res = await this.$api
          .AuditTaskHandle({
            taskId: this.id,
            auditStatus: e,
            auditFailreason: this.reason
          })
          .finally(() => {
            this.btnloadSubmit = false
          })
        this.$message.success('已审核不通过')
        this.show = false
        this.showReason = false
        this.$emit('updateList')
        return false
      } else {
        // 审核通过
        this.$refs.vForm.validate(async (valid) => {
          if (valid) {
            const res = await this.$api
              .AuditTaskHandle({
                taskId: this.id,
                auditStatus: e,
                ...this.form
              })
              .finally(() => {
                this.btnloadSubmit = false
              })
            this.$message.success('已审核通过')
            this.show = false
            this.$emit('updateList')
          } else {
            this.btnloadSubmit = false
          }
        })
      }
    },

    // async auditHandle(params) {
    //   const { id: taskId, merchantTaxId } = await this.formValidate(params.auditStatus)
    //   const res = await this.$api.AuditTaskHandle(Object.assign({ taskId, merchantTaxId }, params))
    //   if (res) {
    //     this.show = false
    //     if (params.auditStatus == 1) {
    //       this.$message.success('审核通过')
    //     } else {
    //       this.$message.warning('审核未通过')
    //     }
    //     this.$emit('updateList')
    //   }
    // },
    // formValidate(state) {
    //   return new Promise((reslove, reject) => {
    //     if (state == 1) {
    //       this.$refs.auditForm.validate((vaild) => {
    //         if (vaild) {
    //           reslove(this.detail)
    //         } else {
    //           return false
    //         }
    //       })
    //     } else {
    //       const { merchantTaxId, ...rest } = this.detail
    //       reslove(rest)
    //     }
    //   })
    // },
    // 获取税目数据源
    async GetSelectMerchantOptions(val) {
      const res = await this.$api.GetSelectMerchantTaxId({ taskId: val })
      const result = this.treeOptions(res.list, null)
      this.itemOptions = result
    },
    // 扁平数据转树状结构
    treeOptions(data, id, level = 1) {
      const treeList = []
      data.forEach((item) => {
        if (item.taxLevel == level) {
          if (item.taxBaseId == id) {
            const children = this.treeOptions(data, item.merchantTaxId, level + 1)
            if (children.length > 0) {
              treeList.push({ ...item, children })
            } else {
              treeList.push({ ...item })
            }
          }
        }
      })
      return treeList
    }
  }
}
</script>
